import React from "react";
import discordIcon from "../../assets/images/images/discord.svg";
import instagramIcon from "../../assets/images/images/instagram.png";
import whatsappIcon from "../../assets/images/images/whatsapp.png";
import emailIcon from "../../assets/images/images/email.png";
import linkedinIcon from "../../assets/images/images/linkedin.png";
import logo from "../../assets/images/images/SHPE_logo_horiz_Florida-Atlantic-University_DKBG.png";

const Footer = () => {
  return (
    <div className="footer-__wrapper">
      <div className="footer-__container">
        <div className="footer-__links-container">
          <a href="/events" className="footer__text-link first">
            Events
          </a>
          <a href="/gallery" className="footer__text-link">
            Gallery
          </a>
          <a href="/sponsors" className="footer__text-link">
            Sponsors
          </a>
          <a href="/resources" className="footer__text-link last">
            Resources
          </a>
        </div>

        <div className="footer-__social-media-icons-container">
          <a
            href="https://discord.com/invite/8Hqu5dRCbv"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-__social-media-icons"
          >
            <img
              src={discordIcon}
              alt="Discord"
              className="footer-__social-media-icon"
            />
          </a>
          <a
            href="https://www.instagram.com/shpe_fau/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-__social-media-icons"
          >
            <img
              src={instagramIcon}
              alt="Instagram"
              className="footer-__social-media-icon"
            />
          </a>
          <a
            href="https://chat.whatsapp.com/Ci7wMkda2311oA3ECwzjUK"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-__social-media-icons"
          >
            <img
              src={whatsappIcon}
              alt="WhatsApp"
              className="footer-__social-media-icon"
            />
          </a>
          <a
            href="mailto:shpefauowls@gmail.com?subject=Re%3A%20SHPE%20FAU"
            className="footer-__social-media-icons"
          >
            <img
              src={emailIcon}
              alt="Email"
              className="footer-__social-media-icon"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/shpe-fauowls/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-__social-media-icons"
          >
            <img
              src={linkedinIcon}
              alt="LinkedIn"
              className="footer-__social-media-icon"
            />
          </a>
        </div>

        <div className="footer-__line-border"></div>

        <div className="footer-__logo-container">
          <img
            src={logo}
            alt="SHPE Florida Atlantic University Logo"
            className="image-11"
          />
        </div>

        <div className="footer-__copyright-container">
          <p className="footer-__text">
            © SHPE Florida Atlantic 2024 - All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
