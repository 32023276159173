import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";
//importing images

import image1 from "../../assets/images/images/IMG_2867.jpg";
import image2 from "../../assets/images/images/IMG_6155.jpeg";
import image3 from "../../assets/images/images/pdd23.png";
import image4 from "../../assets/images/images/IMG_3092.jpg";
import image5 from "../../assets/images/images/IMG_6091.jpeg";
import image6 from "../../assets/images/images/jas-and-leti.jpg";
import image7 from "../../assets/images/images/1500_1.jpg";
import image8 from "../../assets/images/images/fa9575f7c793ffb401c010766818e27d.png";
import image9 from "../../assets/images/images/IMG_4480.jpeg";
const Gallery = () => {
  return (
    <div className="gallery-__wrapper">
      <div className="gallery-__header-container">
        <div className="gallety-__header-content">
          <h1 className="heading-__one">
            <span className="heading-__one-span">SHPE</span> Gallery
          </h1>
        </div>
        <div className="gallery-__header-description">
          <p className="paragraph-__main">
            See our collection of photos from past SHPE events, activities, and
            member achievements. This gallery highlights what our community
            stands for, capturing moments of collaboration, learning, and
            celebration. This page is updated periodically when new events
            occur. Each of these photos represents members participating in
            workshops, professional development events, and fun social
            gatherings. Our Gallery serves as a visual reminder of the strong
            bonds and memories we create together, inspiring others to join and
            become a part of <strong>La Familia</strong>.
          </p>
        </div>
      </div>
      <div className="gallery-__image-gallery-container">
        <div className="gallery-__image-gallery-grid">
          <img
            src={image1}
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            // srcSet="images/IMG_2867-p-500.jpg 500w, images/IMG_2867-p-800.jpg 800w, images/IMG_2867-p-1080.jpg 1080w, images/IMG_2867-p-1600.jpg 1600w, images/IMG_2867-p-2000.jpg 2000w, images/IMG_2867-p-2600.jpg 2600w, images/IMG_2867.jpg 5202w"
            alt=""
            className="gallery-__image-gallery"
          />
          <img
            src={image2}
            loading="lazy"
            id="w-node-_46e4f8f8-5fac-41d8-e369-dbddd6f75250-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.3374938964844px"
            alt=""
            // srcSet="images/IMG_6155-p-500.jpeg 500w, images/IMG_6155-p-800.jpeg 800w, images/IMG_6155-p-1080.jpeg 1080w, images/IMG_6155-p-1600.jpeg 1600w, images/IMG_6155-p-2000.jpeg 2000w, images/IMG_6155-p-2600.jpeg 2600w, images/IMG_6155-p-3200.jpeg 3200w, images/IMG_6155.jpeg 4032w"
            className="gallery-__image-gallery"
          />
          <img
            src={image3}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image4}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image5}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image6}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image7}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image8}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          <img
            src={image9}
            loading="lazy"
            id="w-node-c642ddff-4b4c-b0ac-0fbd-07a327cc4ee7-c5fece91"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 46vw, (max-width: 1439px) 32vw, 389.32501220703125px"
            alt=""
            // srcSet="images/pdd23-p-500.png 500w, images/pdd23-p-800.png 800w, images/pdd23.png 1080w"
            className="gallery-__image-gallery"
          />
          {/* Add the rest of the images here in the same way */}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
