import React from "react";
// import "./EBoard.css"; // Adjust the import based on your CSS structure
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";
import image1 from "../../assets/images/images/Dominic_James_Portrait.png";
import image2 from "../../assets/images/images/Neal_Williams_Portrait.png";
import image3 from "../../assets/images/images/Tania_Alam_Portrait.png";
import image4 from "../../assets/images/images/Angel_Cruz_Portrait.png";
import image5 from "../../assets/images/images/Angelica_Rodriguez_Portrait.png";
import image6 from "../../assets/images/images/Untitled-design-2.png";
import image7 from "../../assets/images/images/Christian_Rodrigues_Portrait.png";
import image8 from "../../assets/images/images/Aaron_Steig_Portrait.jpeg";
import image9 from "../../assets/images/images/Ethan_Prenderegast_Portrait.png";
import image10 from "../../assets/images/images/Fiorella_Bermeo_Portrait.png";
import image11 from "../../assets/images/images/WhatsApp-Image-2024-10-02-at-10.37.25-PM.jpeg";
import image12 from "../../assets/images/images/Daniella_Schwartzman_Portrait.png";
import image13 from "../../assets/images/images/Alejandro_Aramburo_Portrait.jpeg";

//importing flags now:
import jamaicanflag from "../../assets/images/images/jm.svg";
import usflag from "../../assets/images/images/us.svg";
import bangladeshflag from "../../assets/images/images/bd.svg";
import mexicoflag from "../../assets/images/images/mx.svg";
import usvirginislandsflag from "../../assets/images/images/vi.svg";
import haitiflag from "../../assets/images/images/ht.svg";
import brazilflag from "../../assets/images/images/br.svg";
import dominicanrepublicflag from "../../assets/images/images/do.svg";
import peruvianflag from "../../assets/images/images/pe.svg";
import ecuadorflag from "../../assets/images/images/ec.svg";
import colombiaflag from "../../assets/images/images/co.svg";
const EBoard = () => {
  const members = [
    {
      id: 1,
      name: "Dominic James",
      role: "President",
      country: "Jamaica",
      bio: "Dominic is responsible for overseeing all events held by the chapter and serves as a liaison between our chapter and SHPE at the regional and National level.",
      image: image1,
      linkedin: "https://www.linkedin.com/in/dominicjames876",
      flag: jamaicanflag,
    },
    {
      id: 2,
      name: "Neal Williams",
      role: "External Vice-President",
      country: "United States",
      bio: "Neal secures partnerships from companies ensuring mutual benefits between the club and its sponsors, supporting club events and initiatives.",
      image: image2,
      linkedin: "https://www.linkedin.com/in/nealwilliams98/",
      flag: usflag,
    },
    {
      id: 3,
      name: "Tania Alam",
      role: "Internal Vice-President",
      country: "Bangladesh",
      bio: "Tania oversees club operations, internal club affairs, and communications. She seeks out collaborations between clubs for growth and opportunities.",
      image: image3,
      linkedin: "https://www.linkedin.com/in/taniaalam8/",
      flag: bangladeshflag,
    },
    {
      id: 4,
      name: "Angel Cruz",
      role: "Treasury",
      country: "Mexico",
      bio: "Angel oversees chapter budgets, manages all reimbursements, and serves as a point of contact for any financial matters regarding SHPE.",
      image: image4,
      linkedin: "https://www.linkedin.com/in/angel-eduardo-cruz/",
      flag: mexicoflag,
    },
    {
      id: 5,
      name: "Angelica Rodriguez",
      role: "Secretary",
      country: "U.S. Virgin Islands",
      bio: "Angelica serves as a point of contact between FAU and our chapter regarding guidelines, rules and logistics for events/programs.",
      image: image5,
      linkedin: "https://www.linkedin.com/in/angelicamarierod/",
      flag: usvirginislandsflag,
    },
    {
      id: 6,
      name: "Sorahya Eugene",
      role: "Marketing Chair",
      country: "Haiti",
      bio: "Sorahya manages and delegate responsibilities to the marketing committee (social media, photography, video, marketing event, graphic design, apparel).",
      image: image6,
      linkedin: "https://www.linkedin.com/in/sorahya/",
      flag: haitiflag,
    },
    {
      id: 7,
      name: "Christian Rodrigues",
      role: "Event Coordinator",
      country: "Brazil",
      bio: "Christian oversees location logistics for events, securing venues, coordinating setup, and ensuring everything is ready for a smooth event execution.",
      image: image7,
      linkedin: "https://www.linkedin.com/in/christian-rodrigues15/",
      flag: brazilflag,
    },
    {
      id: 8,
      name: "Aaron Steig ",
      role: "Junior SHPE Chair",
      country: "Dominican Republic",
      bio: "Aaron manages the K-12 outreach program, sparking STEM interest through workshops and presentations, and inspiring careers in science and engineering.",
      image: image8,
      linkedin: "https://www.linkedin.com/in/aaron-steig-17ab45164/",
      flag: dominicanrepublicflag,
    },
    {
      id: 9,
      name: "Ethan Prenderegast ",
      role: "National Convention Chair",
      country: "Peru",
      bio: "Ethan is responsible for teaching and preparing engineering students for the national convention through training, workshops, and guidance.",
      image: image9,
      linkedin: "https://www.linkedin.com/in/ethan-prendergast/",
      flag: peruvianflag,
    },
    {
      id: 10,
      name: "Fiorella Bermeo ",
      role: "Membership Chair ",
      country: "Ecuador",
      bio: "Fiorella is responsible for tabling around campus to promote SHPE, engaging engineering students, and sharing opportunities within the organization.",
      image: image10,
      linkedin: "https://www.linkedin.com/in/fiorella-bermeo-55194124b/",
      flag: ecuadorflag,
    },
    {
      id: 11,
      name: "Annabella Zamora ",
      role: "Graduate Representative ",
      country: "Peru",
      bio: "Annabella organizes events, and workshops to help graduate students to secure research opportunities and support their academic development.",
      image: image11,
      linkedin: "https://www.linkedin.com/in/annabellazamora/",
      flag: peruvianflag,
    },
    // {
    //   id: 12,
    //   name: "Daniella Schwartzman",
    //   role: "PR Coordinator ",
    //   country: "Ecuador",
    //   bio: "Daniella coordinates public relations efforts, managing SHPE's campus presence, promoting events, and engaging with engineering students and clubs.",
    //   image: image12,
    //   linkedin: "https://www.linkedin.com/in/daniella-schwartzman/",
    //   flag: ecuadorflag,
    // },
    {
      id: 13,
      name: "Alejandro Aramburo",
      role: "Logistics Chair",
      country: "Colombia",
      bio: "Alejandro is responsible for managing all logistics within SHPE, ensuring smooth coordination of events, meetings, and operations.",
      image: image13,
      linkedin: "https://www.linkedin.com/in/alejandro-aramburo-17b135190/",
      flag: colombiaflag,
    },
    // Add other members similarly
  ];

  return (
    <div className="eboard-__wrapper">
      <div className="eboard-__heading-container">
        <div className="eboard-__heading-text">
          <h1 className="heading-__one">
            Meet our <span className="heading-__one-span">Executive Board</span>
          </h1>
        </div>
        <div className="eboard-__description-text">
          <p className="paragraph-__main">
            Meet the amazing team that makes everything happen. Our E-Board
            consists of 12 passionate and dedicated members, each with unique
            roles and responsibilities. Together, they work to ensure our club
            thrives both on and off campus, creating a lasting impact on
            everything we do.
          </p>
        </div>
      </div>

      <div className="eboard-__card-wrapper">
        <div className="eboard-__card-container">
          {members.map((member) => (
            <div key={member.id} className="eboard-__card">
              <div className="eboard-__card-image">
                <img
                  src={member.image}
                  alt={member.name}
                  className="image-5"
                  loading="lazy"
                />
                <div className="eboard-__image-title">
                  <div className="text-__main small color-orange b">
                    {member.role}
                  </div>
                </div>
              </div>
              <div className="eboard-__card-name">
                <h2 className="heading-__two">
                  {member.name.split(" ")[0]}{" "}
                  <span className="heading-__two-span">
                    {member.name.split(" ")[1]}
                  </span>
                </h2>
              </div>
              <div className="eboard-__card-bio">
                <div className="eboard-__card-bio-container">
                  <img
                    src={member.flag}
                    alt={member.country}
                    className="image-6"
                    loading="lazy"
                  />
                  <div className="paragraph-__main small b">
                    {member.country}
                  </div>
                </div>
              </div>
              <div className="eboard-__card-description">
                <p className="paragraph-__main small center">{member.bio}</p>
              </div>
              <div className="eboard-__card-social">
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-icon-wrapper w-inline-block"
                >
                  <div className="social-media-icon w-embed">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <path
                        fill="currentColor"
                        d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EBoard;
