import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";
import image1 from "../../assets/images/images/fa9575f7c793ffb401c010766818e27d.png";
import image2 from "../../assets/images/images/InternSHPE_4C-300x101.webp";
import image3 from "../../assets/images/images/MentorSHPE_2021_4C_Artboard-300x101.webp";
import image4 from "../../assets/images/images/LeaderSHPE-Logo.png";
import image5 from "../../assets/images/images/mission.png";
import image6 from "../../assets/images/images/shared-vision.png";
import image7 from "../../assets/images/images/15635177.png";

const HomePage = () => {
  return (
    <div className="home-__container">
      <div className="home-__hero-wrapper">
        <div className="home-__hero-image">
          <div className="home-__hero-heading">
            <h1 className="heading-__one">
              <span className="heading-__one-span color-white">Society of</span>{" "}
              Hispanic Professional Engineers at{" "}
              <span className="color-white">
                <br />
                Florida Atlantic University
              </span>
            </h1>
            <div className="home-__text-main-container">
              <div className="text-__main text-bigger color-white">
                <strong>Empowering and Uniting Hispanic Engineers </strong>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-__card-container">
        <div className="home-__card-wrapper">
          <a href="#About" className="home-__card image-1 ">
            <div className="home-__card-content">
              <div className="home-__card-text b">WHAT IS SHPE</div>
            </div>
          </a>
          <a href="#History" className="home-__card image-2 ">
            <div className="home-__card-content">
              <div className="home-__card-text b">HISTORY OF SHPE</div>
            </div>
          </a>
          <a href="/joinus" className="home-__card image-3 ">
            <div className="home-__card-content">
              <div className="home-__card-text b">JOIN US</div>
            </div>
          </a>
        </div>
      </div>

      <section id="About" className="home-__wrapper">
        <h1 className="heading-__one center height_0-9em">
          <span className="heading-__one-span">About</span> SHPE FAU
        </h1>
        <div className="home-__div-container"></div>
        <div className="home-__div-container">
          <p className="paragraph-__main">
            The Florida Atlantic University chapter of the Society of Hispanic
            Professional Engineers was originally founded in August 1997 by
            Manuel Borge. Our chapter has expanded to encompass more than 200
            members, each benefiting from being part of the SHPE Familia. <br />
            <br />
            From mentorship and internships to networking and shared experiences
            with other bright individuals, our members enjoy a range of
            advantages. As we strive for further achievements, we remain
            committed to fostering leadership within the STEM community. We
            empower our youth, support our students, and pay homage to the
            trailblazing professionals who came before us.
            <br />
          </p>
        </div>
        <div className="home-__div-container">
          <img
            src={image1}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 991px) 96vw, (max-width: 1439px) 100vw, 1200px"
            srcSet="images/fa9575f7c793ffb401c010766818e27d-p-500.png 500w, images/fa9575f7c793ffb401c010766818e27d-p-800.png 800w, images/fa9575f7c793ffb401c010766818e27d-p-1080.png 1080w, images/fa9575f7c793ffb401c010766818e27d.png 1104w"
            alt=""
            className="image-__main"
          />
        </div>
        <div className="home-__div-container">
          <div className="home-__div-container-grid">
            <div className="home-__card-program light_orange">
              <div className="home-__card-program-image">
                <img
                  src={image2}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg-copy"
                />
              </div>
              <div className="home-__card-program-header">
                <h2 className="heading-__two color_white">InternSHPE</h2>
              </div>
              <div className="home-__card-program-text">
                <div className="text-block-3">
                  The InternSHPE program supports the internship experience for
                  SHPE members at our corporate partners through intentional
                  programming designed to ensure that interns are successful
                  from the first day of their internship.
                </div>
              </div>
            </div>
            <div className="home-__card-program light_orange">
              <div className="home-__card-program-image">
                <img
                  src={image3}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg-copy"
                />
              </div>
              <div className="home-__card-program-header">
                <h2 className="heading-__two color_white">MentorSHPE</h2>
              </div>
              <div className="home-__card-program-text">
                <div className="text-block-3">
                  Join the MentorSHPE program to grow your network, gain new
                  knowledge, and build your skills. <br />
                  An opportunity to connect with mentors from our chapter and
                  share ideas.
                </div>
              </div>
            </div>
            <div className="home-__card-program light_orange">
              <div className="home-__card-program-image">
                <img
                  src={image4}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg-copy"
                />
              </div>
              <div className="home-__card-program-header">
                <h2 className="heading-__two color_white">LeaderSHPE</h2>
              </div>
              <div className="home-__card-program-text">
                <div className="text-block-3">
                  SHPE empowers the Hispanic community to be leaders — in their
                  chapter, their workplace, and their community – providing
                  opportunities for participants to enhance their professional
                  skills and expand their network.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="History" className="home-__wrapper">
        <div className="home-__div-container">
          <h1 className="heading-__one center">
            <span className="heading-__one-span">History of</span> SHPE
          </h1>
        </div>
        <div className="home-__div-container">
          <p className="paragraph-__main">
            SHPE was founded in Los Angeles, California, in 1974 by a group of
            engineers employed by the city of Los Angeles. Their objective was
            to form a national organization of professional engineers to serve
            as role models in the Hispanic community. <br />‍<br />
            Today, SHPE boasts a robust yet independent network of professional
            and student chapters nationwide, actively promoting the advancement
            and recognition of Hispanics in engineering and technology fields.
            <br />
          </p>
        </div>
        <div className="home-__div-container">
          <div className="home-__card-slide-wrapper">
            <div className="home-__card-slide orange">
              <div className="home-__card-slide-image">
                <img
                  src={image5}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg"
                />
              </div>
              <div className="home-__card-slide-header">
                <h2 className="heading-__two color_white">Mission</h2>
              </div>
              <div className="home-__card-slide-text">
                <div className="text-block-3">
                  SHPE empowers the Hispanic community to reach its potential
                  and impact the world through STEM support and development.
                </div>
              </div>
            </div>
            <div className="home-__card-slide blue">
              <div className="home-__card-slide-image">
                <img
                  src={image6}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg"
                />
              </div>
              <div className="home-__card-slide-header">
                <h2 className="heading-__two color_white">Vision</h2>
              </div>
              <div className="home-__card-slide-text">
                <div className="text-block-3">
                  SHPE empowers the Hispanic community to reach its potential
                  and impact the world through STEM awareness, access, support,
                  and development.
                </div>
              </div>
            </div>
            <div className="home-__card-slide light_orange">
              <div className="home-__card-slide-image">
                <img
                  src={image7}
                  loading="lazy"
                  alt=""
                  className="ourvialuesimg"
                />
              </div>
              <div className="home-__card-slide-header">
                <h2 className="heading-__two color_white">Our Values</h2>
              </div>
              <div className="home-__card-slide-text">
                <div className="text-block-3">
                  <strong>Familia</strong>, <strong>Service</strong>,{" "}
                  <strong>Education</strong>, and <strong>Resilience</strong>{" "}
                  <br />
                  These are the core values of SHPE that reflect its commitment
                  to its members and the greater Hispanic community.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
