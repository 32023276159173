// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer"; // Updated import
import Home from "./pages/Home/Home"; // Updated import
import EBoard from "./pages/Eboard/Eboard"; // Updated import
import DevTeam from "./pages/DevTeam/DevTeam"; // Updated import
import Events from "./pages/Events/Events"; // Updated import
import Gallery from "./pages/Gallery/Gallery"; // Updated import

import Sponsors from "./pages/Sponsors/Sponsors"; // Updated import
import ContactUs from "./pages/ContactUs/ContactUs"; // Updated import
import JoinUs from "./pages/Join-now/Join-now"; // Updated import
import Resources from "./pages/Resources/Resources";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/eboard" element={<EBoard />} />
          <Route path="/dev-team" element={<DevTeam />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/resources" element={<Resources />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
