import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";

//importing images
import image1 from "../../assets/images/images/Jasenia_Guerra_Portrait.png";
import image2 from "../../assets/images/images/WhatsApp-Image-2024-07-30-at-4.10.24-PM.png";
import image3 from "../../assets/images/images/SHPE-Port_Steven-.JPEG";
import image4 from "../../assets/images/images/Sofia_Torres_Portrait.png";
import image5 from "../../assets/images/images/Ryan_Burton_Portrait.png";
import image6 from "../../assets/images/images/joshuahenryphoto.jpg"; //Change it to Joshs image
//importing flags
import panamaflag from "../../assets/images/images/pa.svg";
import dominicanrepublicflag from "../../assets/images/images/do.svg";
import ecuadorflag from "../../assets/images/images/ec.svg";
import colombiaflag from "../../assets/images/images/co.svg";

import usflag from "../../assets/images/images/us.svg";
import haitiflag from "../../assets/images/images/ht.svg";
// Team member data
const teamMembers = [
  {
    name: "Jasenia Guerra",
    role: "Technical Director",
    flag: panamaflag,
    country: "Panama",
    image: image1,
    linkedin: "https://www.linkedin.com/in/jaseniag/",
    description:
      "Jasenia manages website development, overseeing architecture and development teams to ensure a seamless user experience.",
  },
  {
    name: "Mia Marte",
    role: "Full Stack Developer",
    flag: dominicanrepublicflag,
    country: "Dominican Republic",
    image: image2,
    linkedin: "https://www.linkedin.com/in/miamarte/",
    description:
      "Mia manages the front-end and back-end development, ensuring functionality and integration with React and Supabase.",
  },
  {
    name: "Steven Tacle",
    role: "UX/UI Designer",
    flag: ecuadorflag,
    country: "Ecuador",
    image: image3,
    linkedin: "https://www.linkedin.com/in/tacleofficial/",
    description:
      "Steven oversees the user interface design for both web and mobile platforms, keeping SHPE’s marketing guidelines while staying current with design trends.",
  },
  {
    name: "Sofia Torres",
    role: "Front-End Developer",
    flag: colombiaflag,
    country: "Colombia",
    image: image4,
    linkedin: "https://www.linkedin.com/in/sofia-torres-4776b5251/",
    description:
      "Sofia manages the front-end development of the website, ensuring its structure and functionality are maintained using React.",
  },
  {
    name: "Ryan Burton",
    role: "Front-End Developer",
    flag: usflag,
    country: "United States",
    image: image5,
    linkedin: "https://www.linkedin.com/in/ryan-burton-2816b0254/",
    description:
      "Ryan manages the front-end development of the website, ensuring its structure and functionality are maintained using React.",
  },
  {
    name: "Joshua Henry",
    role: "Back-End Developer",
    flag: haitiflag,
    country: "Haiti",
    image: image6,
    linkedin: "https://www.linkedin.com/in/joshua-henry10/",
    description:
      "Joshua manages server-side logic and our database to build secure, scalable applications as well as collaborating with front-end developers.",
  },
];

const DevTeam = () => {
  return (
    <div className="dev-team-__wrapper">
      <div className="dev-team-__heading-container">
        <div className="dev-team-__heading-text">
          <h1 className="heading-__one">
            Meet our
            <span className="heading-__one-span"> Development Team</span>
          </h1>
        </div>
        <div className="dev-team-__description-text">
          <p className="paragraph-__main">
            Meet the amazing web development team that has made it possible to
            build the club page from the ground up to keep members engaged with
            up-to-date information and events. They designed and developed a
            user-friendly platform, ensuring members can easily access
            everything they need to stay connected and involved with the club.
          </p>
        </div>
      </div>
      <div className="dev-team-__card-wrapper">
        <div className="dev-team-__card-container">
          {teamMembers.map((member, index) => (
            <div key={index} className="dev-team-__card">
              <div className="dev-team-__card-image">
                <img
                  src={member.image}
                  loading="lazy"
                  alt={member.name}
                  className="image-5 fit-center"
                />
                <div className="dev-team-__image-title">
                  <div className="text-__main small color-orange b">
                    {member.role}
                  </div>
                </div>
              </div>
              <div className="dev-team-__card-name">
                <h2 className="heading-__two">{member.name}</h2>
              </div>
              <div className="dev-team-__card-bio">
                <div className="dev-team-__card-bio-container">
                  <img src={member.flag} loading="lazy" className="image-6" />
                  <div className="paragraph-__main small b">
                    {member.country}
                  </div>
                </div>
              </div>
              <div className="dev-team-__card-description">
                <p className="paragraph-__main small center">
                  {member.description}
                </p>
              </div>
              <div className="dev-team-__card-social">
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-icon-wrapper w-inline-block"
                >
                  <div className="social-media-icon w-embed">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <path
                        fill="currentColor"
                        d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"
                      ></path>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevTeam;
