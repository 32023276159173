import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";
import "./Sponsors.css";

//importing images
import image1 from "../../assets/images/images/IMG_6091.jpeg";
import image2 from "../../assets/images/images/Untitled-design-39.png";
import image3 from "../../assets/images/images/fa9575f7c793ffb401c010766818e27d.png";
import image4 from "../../assets/images/images/IMG_2908.jpg";
import supabase from "../../client/supabase";

import { useState } from "react";

const Sponsors = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    company: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error before submission
    setSuccessMessage(""); // Reset success message before submission

    const { data, error } = await supabase.from("sponsors").insert([
      {
        first_name: formData.name,
        last_name: formData.lastName,
        email: formData.email,
        company: formData.company,
      },
    ]);
    if (error) {
      console.error("Error submitting data:", error);
      setError(error.message);
    } else {
      console.log("Data submitted successfully:", data);
      setSubmitted(true);
      setSuccessMessage(
        "Thank you! We received your form and will get in touch with you soon."
      );
      setFormData({ name: "", lastName: "", email: "", company: "" }); // Clear form data
    }
  };
  return (
    <div className="sponsors-__wrapper">
      <div className="sponsors-__hero-container">
        <div className="sponsors-__hero-header">
          <h1 className="heading-__one">Our Sponsors</h1>
        </div>
        <div className="sponsors-__description">
          <p className="paragraph-__main">
            Sponsorship plays a vital role in the success of SHPE FAU. By
            partnering with us, you help support our mission to empower Hispanic
            students and professionals in STEM fields. Your contribution not
            only enhances our programs and events but also fosters the growth of
            future leaders. If you’re interested in becoming a sponsor, please
            fill out the form below and we will get back to you in a timely
            manner. Together, we can make a lasting impact.
          </p>
        </div>
        <div className="sponsors-__hero-image-content">
          <div className="sponsors-__hero-image sponsors-image-left">
            <img src={image1} sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 1439px) 30vw, 366.6625061035156px" className="image-7" />
          </div>
          <div className="sponsors-__hero-image sponsors-image-top">
            <img src={image2} sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 1439px) 30vw, 366.6625061035156px" />
          </div>
          <div className="sponsors-__hero-image sponsors-image-bottom">
            <img src={image3} sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 1439px) 30vw, 366.6625061035156px" className="image-9"/>
          </div>
          <div className="sponsors-__hero-image sponsors-image-right">
            <img src={image4} sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 1439px) 30vw, 366.6625061035156px" className="image-8" />
          </div>
        </div>
      </div>
      <div className="sponsors-__container">
        <div className="sponsors-__form-container">
          <div className="sponsors-__form-wrapper">
            <div className="sponsors-__form-input-container">
              {!submitted ? (
                <form
                  id="email-form"
                  name="email-form"
                  method="get"
                  className="form"
                  onSubmit={handleSubmit}
                >
                  <div className="join-now-__form-input-container first-name">
                    <input
                      className="text-field"
                      maxLength="256"
                      name="name"
                      placeholder="e.g. Andrew"
                      type="text"
                      id="First-Name-2"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="join-now-__form-input-container last-name">
                    <input
                      className="text-field"
                      maxLength="256"
                      name="lastName"
                      placeholder="e.g. Smith"
                      type="text"
                      id="Last-Name-2"
                      required
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="join-now-__form-input-container email">
                    <input
                      className="text-field"
                      maxLength="256"
                      name="email"
                      placeholder="e.g. andrewsmith@fau.edu"
                      type="email"
                      id="Email-2"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="join-now-__form-input-container company">
                    <textarea
                      id="field-2"
                      name="company"
                      maxLength="5000"
                      placeholder="Company Name"
                      className="text-field"
                      value={formData.company}
                      onChange={handleInputChange}
                    />
                  </div>
                  <input
                    type="submit"
                    className="main-__submit-button"
                    value="SUBMIT"
                  />
                </form>
              ) : (
                <div
                  className="w-form-done"
                  style={{ display: submitted ? "block" : "none" }}
                >
                  <div>{successMessage}</div> {/* Display success message */}
                </div>
              )}
              {error && (
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.{" "}
                    {error}{" "}
                  </div>
                </div>
              )}
              <div className="sponsors-__form-input-content">
                <div className="text-block-2">
                  <span className="text-span-3">BECOME</span>
                </div>
                <div className="text-block-2-copy-copy">
                  A SPONSOR FOR OUR CHAPTER
                </div>
                <div className="join-now-__paragraph-description-container">
                  <p className="paragraph-__main">
                    To learn more about becoming a sponsor, please explore our
                    sponsorship package by clicking the link below
                  </p>
                  <a
                    href="documents/SPONSORSHPE Package FINAL.pdf"
                    target="_blank"
                    className="paragraph-__main"
                  >
                    SponsorSHPE Package
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
