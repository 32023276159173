import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";

//documents
// import GBM3 from "../../assets/documents/Fall-24-GBM-3.pptx";
// import GBM2 from "../../assets/documents/Fall-24-GBM-2.pptx";
// import GBM3 from "../../assets/documents/Fall-24-GBM-2.pptx"; //change to gbm3
// import GBM4 from "../../assets/documents/Fall-24-GBM-2.pptx"; //change to gbm4

//images
import image1 from "../../assets/images/images/GBM4.png";
import image2 from "../../assets/images/images/GBM3.png";
import image3 from "../../assets/images/images/GBM2.png";
import image4 from "../../assets/images/images/GBM1.png";
import image5 from "../../assets/images/images/shpe-membership.png";
import image6 from "../../assets/images/images/ats-approved-resume-templates.png";
import image7 from "../../assets/images/images/resume101.png";
import image8 from "../../assets/images/images/solidworks-links.png";
import image9 from "../../assets/images/images/owl-central.png";


//icons
import dateIcon from "../../assets/images/images/3.svg";
import viewIcon from "../../assets/images/images/Untitled-design.svg";

const Resources = () => {
  const resources = [
    {
      id: 1,
      href: "https://docs.google.com/presentation/d/1wKz0_2wmwAbC2-c6XWNELhj9N-p-dVb-YcX0eeX2P78/edit?usp=sharing",
      imgSrc: image1,
      date: "10.15.2024",
      title: "General Body Meeting #4",
      description:
        "Recap of GBM 4, where we finalize details for the fast-approaching National Convention and discuss exciting events to close out the fall semester.",
      external: true,
    },
    {
      id: 2,
      href: "https://docs.google.com/presentation/d/1-IAKT3u0t6osQyF0dRLKY8zv6rwW4pEu3zPOu_R02MM/edit?usp=sharing",
      imgSrc: image2,
      date: "10.01.2024",
      title: "General Body Meeting #3",
      description:
        "Recap of GBM 3 where we discuss the upcoming National Convention and fun events such as SHPooky and Top 5 companies event!",
      external: true,
    },
    {
      id: 3,
      href: "https://docs.google.com/presentation/d/1cl_K7BWSihTbQlPPZtZtkDfVVNbfeTSgFx6kn4AXUqs/edit?usp=sharing",
      imgSrc: image3,
      date: "09.17.2024",
      title: "General Body Meeting #2",
      description:
        "Recap of GBM 2 where we went over future events and a fun game of Bingo to get to know the E-Board!",
      external: true,
    },
    {
      id: 4,
      href: "https://docs.google.com/presentation/d/1qgjNrl_thmvJevgX0-gftTu3K5MzdyKK5CgHI9HVjxk/edit?usp=sharing",
      imgSrc: image4,
      date: "08.20.2024",
      title: "General Body Meeting #1",
      description:
        "Recap of GBM 1 where we introduce the new executive board for the 2024-2025 school year!",
      external: true,
    },
    {
      id: 5,
      href: "https://shpe.org/membership/become-a-member/",
      imgSrc: image5,
      title: "SHPE National Membership",
      description:
        "Becoming a SHPE member gives students the opportunity to enhance their academic experience and advance their careers.",
      external: true,
    },
    {
      id: 6,
      href: "https://docs.google.com/document/d/1L4uKaEuPXwwOe0AIuXd_fG4xyy7ibADYEp0v2Lfni-o/edit?usp=sharing",
      imgSrc: image6,
      title: "ATS Approved Resume Templates",
      description:
        "Check out our ATS-approved resume templates designed to help you craft a standout resume.",
      external: true,
    },
    {
      id: 7,
      href: "https://docs.google.com/presentation/d/1H2HsGCWwOhj-gdiTGeUIVOS-VVQLqny35GpNQf7RKHg/edit?usp=sharing",
      imgSrc: image7,
      title: "Resume 101",
      description:
        "Looking for tips on how to enhance your resume? Click here to view a helpful PowerPoint!",
      external: true,
    },
    {
      id: 8,
      href: "https://docs.google.com/document/d/1i3aY-Ahm9le1Xc0OiJTtjqlFpCRx9uLZ2okobDX0Rio/edit?usp=sharing",
      imgSrc: image8,
      title: "Solidworks Helpful Study/Practice Links",
      description:
        "Explore a collection of helpful study resources and practice links designed to guide you through mastering Solidworks.",
      external: true,
    },
    {
      id: 9,
      href: "https://docs.google.com/presentation/d/1CV-vIrbOtxN6laIPUlcZRWKvGk4MAWYLmlqtbrv5Cts/edit?usp=sharing",
      imgSrc: image9,
      title: "How to become a SHPE member on Owl Central and SHPE Connect",
      description:
        "Check out our powerpoint on how to become a SHPE member on Owl Central and SHPE Connect.",
      external: true,
    },
  ];

  return (
    <div className="resource-__wrapper">
      <div className="resource-__container">
        <div className="resource-__header-container">
          <div className="resource-__header-content">
            <h1 className="heading-__one">Resources</h1>
          </div>
          <div className="resource-__header-content">
            <p className="paragraph-__main">
              A comprehensive collection of tools and materials designed to
              support our SHPE members' academic and professional development.
              This section includes General Body Meeting PowerPoint slides,
              Resume 101 tips, and ATS-approved resume templates.
            </p>
          </div>
        </div>
      </div>
      <div className="resource-__grid-container">
        {resources.map((resource) => (
          <a
            id="w-node-_81bfe293-b64b-e258-3331-7e7db8e9e366-6bda8c46"
            key={resource.id}
            href={resource.href}
            className="resource-__content-card-wrapper w-inline-block"
            target={resource.external ? "_blank" : "_self"}
            rel={resource.external ? "noopener noreferrer" : ""}
          >
            <div className="resource-__content-card">
              <div className="resource-__image-container">
                <img
                  src={resource.imgSrc}
                  alt={resource.title}
                  className="resource_image"
                  loading="lazy"
                />
              </div>
              <div className="resource-__time-description">
                {resource.date && (
                  <div className="date_description">
                    <div className="svg_recording_icon">
                      <img
                        width="20"
                        src={dateIcon}
                        alt="Date Icon"
                        className="image_date_icon"
                      />
                    </div>
                    <div className="date_text">{resource.date}</div>
                  </div>
                )}
                <div className="author_description">
                  <div className="svg_recording_icon">
                    <img
                      width="20"
                      src={viewIcon}
                      alt="View Icon"
                      className="image_recording_icon"
                    />
                  </div>
                  <div className="author_text">
                    {resource.external ? "View Website" : "View Slides"}
                  </div>
                </div>
              </div>
              <div className="resource-__content-description">
                <h1 className="heading-__two small">{resource.title}</h1>
                <div className="resource-__summary-description-container">
                  <p className="paragraph-__main small">
                    {resource.description}
                  </p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Resources;
