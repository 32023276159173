import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";
import "./Join-now.css";

//importing images

import image1 from "../../assets/images/images/background.jpg";
import image2 from "../../assets/images/images/foreground.png";
import image3 from "../../assets/images/images/IMG_2908.jpg";
import image4 from "../../assets/images/images/IMG_2861.jpg";
import image5 from "../../assets/images/images/1500.jpg";
import image6 from "../../assets/images/images/IMG_4480.jpeg";

import { useEffect, useState } from "react";
import supabase from "../../client/supabase";

const JoinNow = () => {
  const [scrollY, setScrollY] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    about: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrollY(currentScrollY);
    const newOpacity = Math.max(0, 1 - currentScrollY / 500);
    setOpacity(newOpacity);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, lastName, email, about } = formData;
    const { error } = await supabase
      .from("submissions")
      .insert([{ name, lastName, email, about }]);

    if (error) {
      console.error("Error submitting form:", error);
      // You can set an error message state here if you want to display it
    } else {
      setSubmitted(true);
      setSuccessMessage(
        "Thank you! Your form has been received. To become a full member of SHPE, please join us on OWL Central. You will be redirected to the page to complete your membership in 10 seconds."
      );
      // Redirect after 10 seconds
      setTimeout(() => {
        window.open(
          "https://fau.campuslabs.com/engage/organization/shpe",
          "_blank"
        ); // Open in a new tab
      }, 10000); // 10 seconds
    }
  };
  return (
    <div>
      <div className="join-now-__hero-parallax">
        <div className="parallax_component">
          <img
            sizes="100vw"
            alt=""
            src={image1}
            loading="lazy"
            className="parallax_layer is-2"
          />
          <div className="text-block">
            JOIN OUR <span className="text-span">CHAPTER</span>
          </div>
          <img
            sizes="100vw"
            alt=""
            src={image2}
            loading="lazy"
            className="parallax_layer is-3"
          />
        </div>
      </div>
      <div className="join-now-__grid-4-col-container">
        {[
          {
            src: image3,
            overlayClass: "red-orange",
            text: "MENTORSHPE AND INTERNSHPE",
          },
          {
            src: image4,
            overlayClass: "light-blue",
            text: "PROFESSIONAL WORKSHOPS",
          },
          {
            src: image5,
            overlayClass: "orange",
            text: "PREP FOR NATIONALS",
          },
          {
            src: image6,
            overlayClass: "dark-blue",
            text: "WEEKLY CLUB EVENTS",
          },
        ].map(({ src, overlayClass, text }, index) => (
          <div key={index} className="join-now-__col-1-container">
            <img
              width="Auto"
              height="Auto"
              alt=""
              src={src}
              loading="lazy"
              className={`image-${index + 1}`}
            />
            <div
              style={{ backgroundColor: "rgba(212,63,39,0)" }}
              className={`join-now-__image_overlay ${overlayClass}`}
            ></div>
            <div className="join-now-__text-description-container">
              <div className="text-2">{text}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="join-now-__container">
        <div className="join-now-__form">
          <div className="join-now-__form-block">
            <form
              id="email-form"
              name="email-form"
              method="get"
              className="form"
              onSubmit={handleSubmit}
            >
              {[
                {
                  id: "First-Name",
                  name: "name",
                  placeholder: "First Name",
                  type: "text",
                  value: formData.name,
                  onChange: handleChange,
                },
                {
                  id: "Last-Name",
                  name: "lastName",
                  placeholder: "Last Name",
                  type: "text",
                  value: formData.lastName,
                  onChange: handleChange,
                },
                {
                  id: "Email",
                  name: "email",
                  placeholder: "Email Address",
                  type: "text",
                  value: formData.email,
                  onChange: handleChange,
                },
              ].map(({ id, name, placeholder, type }) => (
                <div key={id} className="join-now-__form-input-container">
                  <input
                    className="text-field"
                    maxLength="256"
                    name={name}
                    placeholder={placeholder}
                    type={type}
                    id={id}
                    required
                  />
                </div>
              ))}
              <div className="join-now-__form-input-container">
                <textarea
                  id="field"
                  name="about"
                  maxLength="5000"
                  placeholder="Tell us a little bit about yourself.."
                  className="text-field"
                  value={formData.about}
                  onChange={handleChange}
                ></textarea>
              </div>
              <input
                type="submit"
                className="main-__submit-button"
                value="JOIN NOW"
                onSubmit={handleSubmit}
              />
            </form>
            {submitted && (
              <div
                className="w-form-done"
                style={{ display: submitted ? "block" : "none" }}
              >
                <div>{successMessage}</div>
              </div>
            )}
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>

          <div className="join-now-__form-description-container">
            <div className="join-now-__form-description">
              <div className="text-block-2">
                J<span className="text-span-3">OIN OUR</span>
              </div>
              <div className="text-block-2-copy">FLORIDA ATLANTIC CHAPTER</div>
            </div>
            <div className="join-now-__paragraph-description-container">
              <p className="paragraph-__main">
                Deciding to become a SHPE member at our university is more than
                just enhancing your academics or advancing your career. It’s
                choosing to be a part of something bigger than yourself. It’s
                moving a whole community forward. It’s fulfilling a promise and
                making dreams come true.
                <br />
                <br />‍<strong>We are a familia.</strong> SHPE is welcoming,
                warm, and inclusive. It’s a place where you don’t have to worry
                about fitting in, because you already belong. Welcome home.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="join-now-__nationals-container">
        <div className="join-now-__nationals-header">
          <h1 className="heading-__one color-black">
            Become a <br />‍<span className="color-orange">SHPE National</span>{" "}
            Member
          </h1>
        </div>
        <div className="join-now-__nationals-description">
          <ul role="list" className="join-now-__nationals-description-list">
            {[
              "Access to more than $300,000 in scholarships",
              "Internship, fellowship, and co-op opportunities",
              "Exclusive access to the SHPE Career Center (upload your resume and connect directly with industry recruiters and hiring managers)",
              "Resumé reviews, recommendations, and rewording",
              "Professional development",
              "Leadership training",
              "Mentoring from a professional member",
              "Discounted registration to national, regional, and local SHPE events",
              "Competition and award opportunities",
              "Access to a nationwide network of industry leaders and peers",
              "Eligibility to run and hold office on the SHPE Board",

            ].map((text, index) => (
              <li
                key={index}
                className="join-now-__nationals-description-list-item"
              >
                {text}
                <br />
              </li>
            ))}
          </ul>
          <a
            id="w-node-b5f3329a-aa68-ee29-2ade-e969e40c7cdc-6f82160e"
            href="https://shpe.org/membership/become-a-member/"
            target="_blank"
            className="main-__submit-button"
          >
            JOIN NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinNow;
