import React from "react";
import "./Events.css";
const Events = () => {
  return (
    <div className="events-__wrapper">
      <div className="events-__header-container">
        <div className="events-__header-content">
          <h1 className="heading-__one">
            <span className="heading-__one-span">SHPE</span> Events
          </h1>
        </div>
        <div className="events-__header-description">
          <p className="paragraph-__main">
            SHPE hosts weekly events designed to prepare you for our main event,
            the annual <strong>SHPE National Convention</strong>. Each session
            focuses on building and enhancing your professional skills, giving
            you the tools and confidence needed to excel in interviews and
            increase your chances of securing internships and job offers. We
            also organize fun events for community engagement and team building,
            helping you connect with fellow members and strengthen the SHPE
            community.
          </p>
        </div>
      </div>

      <div className="events-__events-list-container">
        <div className="events-__events-list-grid">
          {eventsList.map((event, index) => (
            <div key={index} className="events-__events-list">
              <div className="events-__events-list-header">
                <h2 className="heading-__two small">
                  {event.name}{" "}
                  <span className="color-orange">{event.highlight}</span>
                </h2>
              </div>
              <div className="events-__events-list-line"></div>
              <div className="events-__events-list-description">
                <p className="paragraph-__main small">{event.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="events-__calendar-wrapper">
        <div className="events-__calendar-container">
          <div className="w-embed w-iframe">
            <div className="calendar-container">
              <iframe
                src="https://calendar.google.com/calendar/embed?src=iskarohlo26lv1p3fhfvjfrubs%40group.calendar.google.com&ctz=America%2FNew_York"
                style={{ border: 0 }}
                frameBorder="0"
                scrolling="no"
                className="responsive-calendar"
                title="Google Calendar"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const eventsList = [
  {
    name: "Leader",
    highlight: "SHPE",
    description:
      "An event with workshops and guest speakers from different companies focused on developing leadership skills. SHPE members will engage in hands-on activities, network with professionals, and gain valuable information into effective leadership strategies, empowering them for personal and professional growth.",
  },
  {
    name: "Mentor",
    highlight: "SHPE",
    description:
      "A program connecting SHPE members with professionals and peers to support personal and professional growth. Participants engage in one-on-one sessions, and group activities, gaining valuable guidance and building lasting relationships to help them achieve their goals.",
  },
  {
    name: "SHPE",
    highlight: "ooky",
    description:
      "An annual fun-filled Halloween event featuring themed activities, games, and costume contests designed to build collaborations and team spirit among members.",
  },
  {
    name: "Professional Development",
    highlight: "Day",
    description:
      "A premier event featuring career workshops, mock interviews, and mentorship opportunities, designed to enhance professional skills and prepare participants for career success.",
  },
  {
    name: "General Body",
    highlight: "Meeting",
    description:
      "Regularly scheduled gatherings where members come together to stay informed about upcoming events, initiatives, and opportunities within SHPE. These meetings provide a platform for members to connect, share updates, and engage in discussions about academic, professional, and personal growth.",
  },
  {
    name: "SHPE",
    highlight: "xClub Collaboration",
    description:
      "SHPE is proud to partner with other clubs and organizations to feature workshops, networking opportunities, and team-building activities. These collaborations provide diverse learning experiences, allowing members to gain skills, and connect with peers.",
  },
];

export default Events;
