import React from "react";
import "../../assets/css/normalize.css";
import "../../assets/css/fau-shpe.webflow.css";
import "../../assets/css/webflow.css";

//importing images
import image1 from "../../assets/images/images/IMG_6091.jpeg";
import image2 from "../../assets/images/images/Untitled-design-39.png";
import image3 from "../../assets/images/images/fa9575f7c793ffb401c010766818e27d.png";
import image4 from "../../assets/images/images/IMG_2908.jpg";
import image5 from "../../assets/images/images/IMG_6155.jpeg";
import image6 from "../../assets/images/images/jas-and-leti.jpg";

//icons
import instagramicon from "../../assets/images/images/instagram.png";
import discordicon from "../../assets/images/images/discord.svg";
import whatsappicon from "../../assets/images/images/whatsapp.png";
import linkedinicon from "../../assets/images/images/linkedin.png";
import emailicon from "../../assets/images/images/email.png";

const ContactUs = () => {
  return (
    <div className="contact-us-__wrapper">
      <div className="contact-us-__container">
        {/* Hero Section */}
        <div className="contact-us-__hero-wrapper">
          <div className="contact-us-__hero-container">
            <div className="contact-us-__hero-header">
              <h1 className="heading-__one">
                Stay Connected
                <span className="heading-__one-span"> Con La Familia</span>
              </h1>
            </div>
            <div className="contact-us-__hero-image-content">
              <div className="contact-us-__hero-image">
                <img
                  src={image1}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.32501220703125px"
                  //srcSet="images/IMG_6091-p-500.jpeg 500w, images/IMG_6091-p-800.jpeg 800w, images/IMG_6091-p-1080.jpeg 1080w, images/IMG_6091-p-1600.jpeg 1600w, images/IMG_6091-p-2000.jpeg 2000w, images/IMG_6091-p-2600.jpeg 2600w, images/IMG_6091.jpeg 3024w"
                  alt=""
                  className="image-7"
                />
              </div>
              <div className="contact-us-__hero-image">
                <img
                  src={image2}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.3374938964844px"
                  //srcSet="images/Untitled-design-39-p-500.png 500w, images/Untitled-design-39-p-800.png 800w, images/Untitled-design-39-p-1080.png 1080w, images/Untitled-design-39.png 1366w"
                  alt=""
                />
              </div>
              <div className="contact-us-__hero-image">
                <img
                  src={image3}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.32501220703125px"
                  // srcSet="images/fa9575f7c793ffb401c010766818e27d-p-500.png 500w, images/fa9575f7c793ffb401c010766818e27d-p-800.png 800w, images/fa9575f7c793ffb401c010766818e27d-p-1080.png 1080w, images/fa9575f7c793ffb401c010766818e27d.png 1104w"
                  alt=""
                  className="image-9"
                />
              </div>
              <div className="contact-us-__hero-image">
                <img
                  src={image4}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.32501220703125px"
                  //srcSet="images/IMG_2908-p-500.jpg 500w, images/IMG_2908-p-800.jpg 800w, images/IMG_2908-p-1080.jpg 1080w, images/IMG_2908-p-1600.jpg 1600w, images/IMG_2908-p-2000.jpg 2000w, images/IMG_2908-p-2600.jpg 2600w, images/IMG_2908-p-3200.jpg 3200w, images/IMG_2908.jpg 5202w"
                  alt=""
                  className="image-8"
                />
              </div>
              <div className="contact-us-__hero-image">
                <img
                  src={image5}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.32501220703125px"
                  //srcSet="images/IMG_2908-p-500.jpg 500w, images/IMG_2908-p-800.jpg 800w, images/IMG_2908-p-1080.jpg 1080w, images/IMG_2908-p-1600.jpg 1600w, images/IMG_2908-p-2000.jpg 2000w, images/IMG_2908-p-2600.jpg 2600w, images/IMG_2908-p-3200.jpg 3200w, images/IMG_2908.jpg 5202w"
                  alt=""
                  className="image-8"
                />
              </div>
              <div className="contact-us-__hero-image">
                <img
                  src={image6}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 1439px) 33vw, 393.32501220703125px"
                  //srcSet="images/IMG_2908-p-500.jpg 500w, images/IMG_2908-p-800.jpg 800w, images/IMG_2908-p-1080.jpg 1080w, images/IMG_2908-p-1600.jpg 1600w, images/IMG_2908-p-2000.jpg 2000w, images/IMG_2908-p-2600.jpg 2600w, images/IMG_2908-p-3200.jpg 3200w, images/IMG_2908.jpg 5202w"
                  alt=""
                  className="image-8"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Body Section */}
        <div className="contact-us-__body-wrapper">
          <div className="contact-us-__body-container">
            <div className="contact-us-__body-content">
              <div className="contact-us-__body-header-wrapper">
                <h1 className="heading-__two">
                  Ways to{" "}
                  <span className="heading-__two-span">stay connected.</span>
                </h1>
              </div>
              <div className="contact-us-__body-paragraph-wrapper">
                <p className="paragraph-__main">
                  Staying connected with us on Instagram, LinkedIn and our
                  WhatsApp is essential to keep up with our latest events and
                  updates. Follow us for exclusive insights, real-time
                  announcements, and the opportunity to engage with our
                  community. Have a question that requires submitting
                  documentation? No worries, just send us an email.
                </p>
              </div>
            </div>

            <div className="contact-us-__body-content">
              <div className="contact-us-__body-content-wrapper">
                <a
                  href="https://www.instagram.com/shpe_fau/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-us-__body-social-media-container"
                >
                  <div className="contact-us-__social-media-icon-container">
                    <img
                      src={instagramicon}
                      loading="lazy"
                      alt=""
                      className="image-10"
                    />
                  </div>
                  <div className="contact-us-__social-media-description-container">
                    <p className="paragraph-__main no-margin b">@shpe_fau</p>
                  </div>
                </a>
                <a
                  href="https://discord.com/invite/8Hqu5dRCbv"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-us-__body-social-media-container"
                >
                  <div className="contact-us-__social-media-icon-container">
                    <img
                      src={discordicon}
                      loading="lazy"
                      alt=""
                      className="image-10"
                    />
                  </div>
                  <div className="contact-us-__social-media-description-container">
                    <p className="paragraph-__main no-margin b">
                      SHPE FAU on Discord
                    </p>
                  </div>
                </a>
                <a
                  href="https://chat.whatsapp.com/Ci7wMkda2311oA3ECwzjUK"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-us-__body-social-media-container"
                >
                  <div className="contact-us-__social-media-icon-container">
                    <img
                      src={whatsappicon}
                      loading="lazy"
                      alt=""
                      className="image-10"
                    />
                  </div>
                  <div className="contact-us-__social-media-description-container">
                    <p className="paragraph-__main no-margin b">
                      Join SHPE FAU on WhatsApp
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/shpe-fauowls/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-us-__body-social-media-container"
                >
                  <div className="contact-us-__social-media-icon-container">
                    <img
                      src={linkedinicon}
                      loading="lazy"
                      alt=""
                      className="image-10"
                    />
                  </div>
                  <div className="contact-us-__social-media-description-container">
                    <p className="paragraph-__main no-margin b">
                      SHPE FAU on LinkedIn
                    </p>
                  </div>
                </a>
                <a
                  href="mailto:shpefauowls@gmail.com?subject=Re%3A%20SHPE%20FAU"
                  className="contact-us-__body-social-media-container"
                >
                  <div className="contact-us-__social-media-icon-container">
                    <img
                      src={emailicon}
                      loading="lazy"
                      alt=""
                      className="image-10"
                    />
                  </div>
                  <div className="contact-us-__social-media-description-container">
                    <p className="paragraph-__main no-margin b">
                      shpefauowls@gmail.com
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
